<template>
  <slot />
</template>

<script setup lang="ts">
useHead({
  meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'robots', content: 'noindex,nofollow' }
      ],
})
</script>

<style>
</style>
